@font-face {
  font-family: "Lato";
  src: local("Lato"), url("./fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Upgrade Black";
  src: local("Upgrade Black"),
    url("./fonts/Upgrade-Black.ttf") format("truetype");
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #222326;
  color: #fff;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: 12px;
  overflow: auto;
  word-wrap: anywhere;
  width: 100%;
}

a {
  color: #fff;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  background: url(https://upload.wikimedia.org/wikipedia/commons/7/7e/Calendar_font_awesome-white.svg)
    no-repeat;
  border-width: thin;
}

.secure-fields__input {
  padding: 12px 12px 10px;
  background: #222326;
  border-radius: 14px;
  margin-top: 8px;
  margin-bottom: 4px;
  height: 2em;
}
